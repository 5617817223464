import { ForwardedRef, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classnames from 'classnames';

export interface LinkBlueProps<T> extends LinkProps<T> {}

/** link that looks like the standard blue links people expect */
const LinkBlueRef = forwardRef(LinkBlue) as <T>(
  // We are using a type assertion here because we want the external API to reflect the generic 'LinkBlue<T>' while still supporting refs
  // see: https://fettblog.eu/typescript-react-generic-forward-refs/#option-1%3A-type-assertion
  props: LinkBlueProps<T> & { ref?: React.ForwardedRef<HTMLAnchorElement> }
) => ReturnType<typeof LinkBlue>;

export default LinkBlueRef;

function LinkBlue<T>(
  { className, children, ...props }: LinkBlueProps<T>,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  return (
    <Link
      {...props}
      ref={ref}
      className={classnames(
        className,
        'text-blue-500 ring-indigo-200 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 '
      )}>
      {children}
    </Link>
  );
}
