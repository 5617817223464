import { CellProps } from 'react-table';
import { Cells } from 'envoc-table';
import { LinkBlue } from './';

interface LinkCellProps<T extends { id?: number }> extends CellProps<T> {
  to: string;
}

export default function LinkCell<T extends { id?: number }>(
  props: LinkCellProps<T>
) {
  if (!props.value) {
    return <Cells.Cell value="N/A" />;
  }

  const { id } = props.row.original;
  const { to } = props;

  return (
    <Cells.Cell
      value={
        <LinkBlue target="_blank" to={`${to}/${id}`}>
          {props.value}
        </LinkBlue>
      }
    />
  );
}
