import { CellProps } from 'react-table';
import { Cells } from 'envoc-table';
import { SheriffStatusLabel } from './SubmissionStatus';

export default function SheriffStatusCell(props: CellProps<[]>) {
  return (
    <Cells.Cell
      value={
        <SheriffStatusLabel
          status={props.value}
          labelStyle="px-4 py-2 w-24 max-w-md"
        />
      }
    />
  );
}
