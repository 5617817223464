import classnames from 'classnames';
import { Cells } from 'envoc-table';

export default function CustomNaCell({
  value,
  className,
  allowEmptyString,
  ...props
}: any) {
  if (value && (value !== '' || props.allowEmptyString)) {
    return <Cells.Cell {...props} className={className} value={value} />;
  } else {
    return (
      <Cells.Cell
        {...props}
        className={classnames(className, 'na-cell')}
        value="N/A"
      />
    );
  }
}
