import { PriorFiscalYearAdjustmentStatus } from 'api';
import SubmissionStatusLabel from '../SubmissionStatus/SubmissionStatusLabel';

interface PriorFiscalYearAdjustmentStatusLabelProps {
  status: PriorFiscalYearAdjustmentStatus | null;
  labelStyle?: string;
  prependLabel?: string;
}

export default function PriorFiscalYearAdjustmentStatusLabel({
  status,
  labelStyle,
  prependLabel,
}: PriorFiscalYearAdjustmentStatusLabelProps) {
  const formatStatus = (status: PriorFiscalYearAdjustmentStatus | null) => {
    switch (status) {
      case PriorFiscalYearAdjustmentStatus.Approved:
        return {
          color: 'bg-green-200 text-green-900',
          text: 'Approved',
        };
      case PriorFiscalYearAdjustmentStatus.Rejected:
        return {
          color: 'bg-red-200 text-red-900',
          text: 'Rejected',
        };
      case PriorFiscalYearAdjustmentStatus.Pending:
        return {
          color: 'bg-yellow-200 text-yellow-900',
          text: 'Pending',
        };
      case PriorFiscalYearAdjustmentStatus.Reconciled:
        return {
          color: 'bg-blue-200 text-blue-900',
          text: 'Reconciled',
        };
      default:
        return {
          color: 'bg-green-200 text-gray-900',
          text: 'Open',
        };
    }
  };

  const { color, text } = formatStatus(status);

  return (
    <SubmissionStatusLabel
      labelStyle={labelStyle}
      color={color}
      text={text}
      prependLabel={prependLabel}
    />
  );
}
