import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, SubmitFormButton, validators } from 'envoc-form';
import { AccountService, PasswordLoginCommand } from 'api';
import { LinkBlue, SingleActionPage, SupportText } from 'shared/components';
import { InputWithValidation } from 'shared/forms';
import { useLoginRedirect } from 'shared/hooks';
import { authActions, authSelectors, authTicketStates } from 'shared/state';
import { useHistory } from 'react-router';
import { SignInResult } from 'typings';

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { restoreFromLogin } = useLoginRedirect();

  const { authTicketState, user } = useSelector(authSelectors.getAuth);

  useEffect(() => {
    dispatch(authActions.allowRedirects());
  }, [dispatch]);

  useEffect(() => {
    if (user?.signInResult === SignInResult.RequiresTwoFactor) {
      history.push('/two-factor-login');
    }

    if (
      (user && authTicketState === authTicketStates.userAuthTicketChecked) ||
      authTicketState === authTicketStates.userAuthTicketChecking
    ) {
      restoreFromLogin();
    }
  });

  return (
    <SingleActionPage>
      <Form
        onSubmit={handleSubmit}
        ignoreLostChanges={true}
        className="sm:w-96">
        <h1 className="mt-2 text-3xl font-extrabold text-gray-900">Login</h1>
        <SupportText className="mt-1">Sign In to your account</SupportText>

        <div className="mt-6">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700">
            Email address
          </label>
          <div className="relative mt-1 group">
            <div className="absolute flex h-10 pointer-events-none w-9">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="!w-5 !h-5 m-auto text-gray-400 group-focus-within:text-indigo-500"
              />
            </div>
            <InputWithValidation
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              validate={validators.required}
              className="px-3 py-2 pl-8"
            />
          </div>
        </div>

        <div className="mt-4">
          <label
            htmlFor="email"
            className="block w-10 text-sm font-medium text-gray-700">
            Password
          </label>
          <div className="relative mt-1 group">
            <div className="absolute flex h-10 pointer-events-none w-9">
              <FontAwesomeIcon
                icon={faLock}
                className="!w-5 !h-5 m-auto text-gray-400 group-focus-within:text-indigo-500"
              />
            </div>
            <InputWithValidation
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              validate={validators.required}
              className="block w-full px-3 py-2 pl-8"
            />
          </div>
        </div>

        <SubmitFormButton className="w-full mt-8" allowPristineSubmit={true}>
          Login
        </SubmitFormButton>

        <LinkBlue className="block mt-12" to="/forgot-password">
          Forgot your password? Click here
        </LinkBlue>
      </Form>
    </SingleActionPage>
  );

  function handleSubmit(values: PasswordLoginCommand) {
    return AccountService.login({ body: values }).then((resp) => {
      const signInResult = resp.result;
      if (
        signInResult === SignInResult.Succeeded ||
        signInResult === SignInResult.RequiresTwoFactor
      ) {
        dispatch(authActions.loadUser());
      }
    });
  }
}
