import { CellProps } from 'react-table';
import { Cells } from 'envoc-table';
import PriorFiscalYearAdjustmentStatusLabel from './PriorFiscalYearAdjustments/PriorFiscalYearAdjustmentStatusLabel';

export default function PriorFiscalYearAdjustmentStatusCell(
  props: CellProps<[]>
) {
  return (
    <Cells.Cell
      value={
        <PriorFiscalYearAdjustmentStatusLabel
          status={props.value}
          labelStyle="px-4 py-2 w-24 max-w-md"
        />
      }
    />
  );
}
