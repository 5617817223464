import { CellProps } from 'react-table';
import { Cells } from 'envoc-table';

export default function ExplanationCell(props: CellProps<[]>) {
  return (
    <Cells.Cell
      className="flex flex-wrap max-w-lg break-normal !whitespace-normal"
      value={props.value}
    />
  );
}
