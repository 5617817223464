import { SubmissionAction } from 'api';
import SubmissionStatusLabel from './SubmissionStatusLabel';

interface TreasuryStatusLabelProps {
  status: SubmissionAction | null;
  labelStyle?: string;
  prependLabel?: string;
}

export default function TreasuryStatusLabel({
  status,
  labelStyle,
  prependLabel,
}: TreasuryStatusLabelProps) {
  const { color, text } = formatStatus(status);

  return (
    <SubmissionStatusLabel
      labelStyle={labelStyle}
      color={color}
      text={text}
      prependLabel={prependLabel}
    />
  );

  function formatStatus(status: SubmissionAction | null) {
    switch (status) {
      case SubmissionAction.Submitted:
        return {
          color: 'bg-yellow-200 text-yellow-900',
          text: 'Pending',
        };
      case SubmissionAction.Rejected:
        return {
          color: 'bg-red-200 text-red-900',
          text: 'Rejected',
        };
      case SubmissionAction.Approved:
        return {
          color: 'bg-blue-200 text-blue-900',
          text: 'Approved',
        };
      default:
        return {
          color: 'bg-gray-200 text-gray-900',
          text: 'N/A',
        };
    }
  }
}
