import { CellProps } from 'react-table';
import { Cells } from 'envoc-table';

export default function DateOnlyCell(props: CellProps<[]>) {
  const date = new Date(props.value);

  if (!props.value) {
    return <Cells.Cell value="" />;
  }

  return (
    <Cells.Cell
      value={date.toLocaleDateString('default', {
        timeZone: 'UTC',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })}
    />
  );
}
