import { SheriffStatus } from 'api';
import SubmissionStatusLabel from './SubmissionStatusLabel';

interface SheriffStatusLabelProps {
  status: SheriffStatus | null;
  labelStyle?: string;
  prependLabel?: string;
}

export default function SheriffStatusLabel({
  status,
  labelStyle,
  prependLabel,
}: SheriffStatusLabelProps) {
  const { color, text } = formatStatus(status);

  return (
    <SubmissionStatusLabel
      labelStyle={labelStyle}
      color={color}
      text={text}
      prependLabel={prependLabel}
    />
  );

  function formatStatus(status: SheriffStatus | null) {
    switch (status) {
      case SheriffStatus.Pending:
        return {
          color: 'bg-yellow-200 text-yellow-900',
          text: 'Pending',
        };
      case SheriffStatus.Rejected:
        return {
          color: 'bg-red-200 text-red-900',
          text: 'Rejected',
        };
      case SheriffStatus.Approved:
        return {
          color: 'bg-blue-200 text-blue-900',
          text: 'Approved',
        };
      case SheriffStatus.Missing:
        return {
          color: 'bg-gray-200 text-gray-900',
          text: 'Missing',
        };
      default:
        return {
          color: 'bg-green-200 text-green-900',
          text: 'Open',
        };
    }
  }
}
