import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AccountService } from 'api';

declare module 'axios' {
  export interface AxiosRequestConfig {
    hasLoadingMessage?: boolean;
  }
}

export const loadUser = createAsyncThunk('auth/loadUser', async () => {
  const meResult = await AccountService.getLoggedInUserDetails({
    hasLoadingMessage: true,
  });
  return meResult.result ?? null;
});

export const logout = createAsyncThunk('auth/logout', async () => {
  await axios.post('/api/account/logout', {
    hasLoadingMessage: true,
  });
  // need to re-get a new Xsrf - they are tied to user
  await axios.get('/api/account/me', {
    hasLoadingMessage: true,
  });
});

export const setNoAuth = createAction('auth/setNoAuth');

export const allowRedirects = createAction('auth/allowRedirects');

export const setAuthError = createAction('auth/setAuthError');
