import { Parish } from 'typings';
import { AuthReduxState } from './types';

export const getAuth = (state: AuthReduxState) => state.auth;
export const getUser = (state: AuthReduxState) => getAuth(state).user;
export const getAuthTicketState = (state: AuthReduxState) =>
  getAuth(state).authTicketState;
export const getUserRole = (state: AuthReduxState) => getUser(state)?.userRole;
export const getUserParish = (state: AuthReduxState): Parish => {
  return {
    id: getUser(state)?.parishId ?? null,
    name: getUser(state)?.parishName ?? null,
  };
};
