import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SignInResult } from 'typings';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, SubmitFormButton, validators } from 'envoc-form';
import { AccountService, LoginWithTwoFaCommand } from 'api';
import { ButtonLink, SingleActionPage, SupportText } from 'shared/components';
import { InputWithValidation } from 'shared/forms';
import { useLoginRedirect } from 'shared/hooks';
import { authActions, authSelectors, authTicketStates } from 'shared/state';

export default function TwoFactorLogin() {
  const dispatch = useDispatch();
  const { restoreFromLogin, redirectToLogin } = useLoginRedirect();

  const { authTicketState, user } = useSelector(authSelectors.getAuth);

  useEffect(() => {
    dispatch(authActions.allowRedirects());
  }, [dispatch]);

  useEffect(() => {
    if (
      (user &&
        user?.signInResult !== SignInResult.RequiresTwoFactor &&
        authTicketState === authTicketStates.userAuthTicketChecked) ||
      authTicketState === authTicketStates.userAuthTicketChecking
    ) {
      restoreFromLogin();
    }
  });

  return (
    <SingleActionPage>
      <Form
        onSubmit={handleSubmit}
        ignoreLostChanges={true}
        className="sm:w-96">
        <h1 className="mt-2 text-3xl font-extrabold text-gray-900">
          Confirm Login
        </h1>
        <SupportText className="mt-1">
          For additional security, a code has been sent to your email address.
        </SupportText>
        <div className="mt-4">
          <label
            htmlFor="code"
            className="block text-sm font-medium text-gray-700">
            Two Factor Code
          </label>
          <div className="relative mt-1 group">
            <div className="absolute flex h-10 pointer-events-none w-9">
              <FontAwesomeIcon
                icon={faLock}
                className="!w-5 !h-5 m-auto text-gray-400 group-focus-within:text-indigo-500"
              />
            </div>
            <InputWithValidation
              id="code"
              name="code"
              type="text"
              autoComplete="one-time-code"
              validate={validators.required}
              className="block w-full px-3 py-2 pl-8"
            />
          </div>
        </div>
        <SubmitFormButton className="w-full mt-8" allowPristineSubmit={true}>
          Confirm
        </SubmitFormButton>
        <ButtonLink
          type="button"
          color="indigo"
          onClick={() => {
            dispatch(authActions.setAuthError());
            redirectToLogin(true);
          }}
          className="mt-8">
          Having trouble signing in? Click to return to login
        </ButtonLink>
      </Form>
    </SingleActionPage>
  );

  function handleSubmit(values: LoginWithTwoFaCommand) {
    return AccountService.loginWith2Fa({ body: values }).then((resp) => {
      const signInResult = resp.result;
      if (signInResult) {
        dispatch(authActions.loadUser());
      }
    });
  }
}
