//This technically comes back from the server from the overriden .ToString() on SignInResult
export enum SignInResult {
    Failed = 'Failed',
    Succeeded = 'Succeeded',
    RequiresTwoFactor = 'RequiresTwoFactor',
    NotAllowed = 'NotAllowed',
    Lockedout = 'Lockedout',
  }

  export interface Parish {
    id: number | null;
    name: string | null;
  }