import React, { PropsWithChildren, ReactNode, Suspense, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { sortBy } from 'lodash';
import routeFiles from 'route-files';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type RouteElement = (props: any) => JSX.Element;

// stolen from react router 6 (beta) so we can jump to Outlet based quickly
export interface RouteConfig {
  children: RouteConfig[];
  element: RouteElement | null; // note: this is normally a 'react node' react router 6 (beta) - but we want to inject children into it here as a workaround
  path: string;
}

export default function FileSystemRoutes() {
  const result = useMemo(() => {
    let root = {
      children: [],
      path: '',
      element: null,
    } as RouteConfig;

    for (let index = 0; index < routeFiles.length; index++) {
      const element = routeFiles[index];
      const Component = React.lazy(element.importFunc as any);

      // see: 2e65dc0521f571936f050b57d888fcdbede2c239 - this will be an awaited element using outlet instead of directly using children
      let parent = root;

      // take each part in a path and ensure we have a route for each name
      // this way we can handle folder routes that have no layout
      const parts = element.path.split('/');
      for (let index = 0; index < parts.length; index++) {
        const part = parts[index];

        const effectivePath =
          part === ''
            ? '/'
            : part === '404'
            ? '*' // note: '*' here eventually means the right thing for react router 6 for 'useRoutes' that takes a configuration object
            : part.startsWith(':')
            ? part
            : part // convert to kebab case
                .replace(/([a-z])([A-Z])/g, '$1-$2')
                .replace(/\s+/g, '-')
                .toLowerCase();

        if (part === '_Layout') {
          // this should wrap all the children, including index routes - there should exist only a single _Layout instance per path part (e.g. folder)
          const LayoutElement = ({
            children,
            ...rest
          }: PropsWithChildren<any>) => {
            return (
              <Suspense fallback={<BlockingLoader>{children}</BlockingLoader>}>
                <Component {...rest}>{children}</Component>
              </Suspense>
            );
          };
          // we use an object like this so that react component render can see 'LayoutElement' to help debug
          parent.element = LayoutElement;
        } else if (index === parts.length - 1) {
          // we are a leaf, go ahead and add
          let item = parent.children.find((x) => x.path === effectivePath);
          if (!item) {
            item = {
              path: effectivePath,
              children: [],
              element: null,
            };
            parent.children.push(item);
          }

          const Leaf = ({ children, ...rest }: PropsWithChildren<any>) => {
            return (
              <Suspense fallback={<BlockingLoader>{children}</BlockingLoader>}>
                <Component {...rest}>{children}</Component>
              </Suspense>
            );
          };
          // we use an object like this so that react component render can see 'Leaf' to help debug
          item.element = Leaf;
        } else {
          // we still have folders to parse, keep going deeper
          let newParent = parent.children.find((x) => x.path === effectivePath);
          if (!newParent) {
            newParent = {
              path: effectivePath,
              element: null, // TODO we should render Outlet here eventually, our render will handle the null case correctly
              children: [],
            };
            parent.children.push(newParent);
          }

          parent = newParent;
        }
      }
    }

    // because we are using react router 5 instead of 6 - we cannot simply use the hierarchical configuration option
    // see: https://reacttraining.com/blog/react-router-v6-pre/#object-based-routes
    // we want to emulate that in react router 5 until we get outlet
    const children = renderFlatRoutes(
      root,
      root.path === '' ? [] : [root.path],
      root.element === null ? [] : [root.element]
    );

    // so, we don't want to use the 'key' prop because it causes everything to mount and unmount
    // This is like writing out a switch with several route elements manually out - instead of using map(...) with a key
    // e.g. <Switch><Route /><Route /><Route /></Switch> does not need 'key' props on each route
    // this allows React to avoid unmounting a component when the path to that component remains unchanged

    // Assume: Two routes with root -> AdminLayout -> {path}
    // so:
    // 1) <Route path="foo" render={root -> AdminLayout -> Foo} />
    // 2) <Route path="bar" render={root -> AdminLayout -> Bar} />
    // When switching between foo and bar, React renders the following states
    // on '/foo': Route -> root -> AdminLayout -> Foo
    // on '/bar': Route -> root -> AdminLayout -> Bar
    // Since we didn't set the 'key' on the route - React simply sees the same component type at the same location and doesn't need to unmount
    // the only unmounted component would be Foo, replaced by Bar
    // compare that with if each Route above had a key - everything would get unmounted on each route change
    return React.createElement.apply(null, [Switch, {}, ...children]);
  }, []);

  return result;
}

function BlockingLoader({ children }: { children?: ReactNode }) {
  return (
    <>
      <div className="fixed top-0 left-0 z-50 flex flex-col items-center justify-center w-screen h-screen gap-2 text-gray-600 bg-white">
        <div>Loading Content</div>
        <FontAwesomeIcon
          icon={faSpinner}
          className="!w-12 !h-12 animate-spin"
        />
      </div>
      <div className="hidden">{children}</div>
    </>
  );
}

// take the hierarchal route configuration object and render as a flat list of routes inside a single switch
// e.g. each possible route exists inside this single switch
// the reason we use a single switch is because we can take advantage of switch's "fall through" behavior for multiple items that might match when considering order
// namely, Switch iterates over children and matches the first (top down order) child which matches
// so, we can have multiple 404 in different parts of the tree that are more specific to say, the layout
// e.g. the admin layout's 404 page being distinct from the root 404 page
// this is achieved by careful ordering of child routes
function renderFlatRoutes(
  config: RouteConfig,
  pathsVisited: string[],
  elementsVisited: RouteElement[]
): JSX.Element[] {
  const Element = config.element;
  if (config.children.length === 0) {
    // we are in a leaf of the tree, so a route should be rendered

    const isIndexPage = config.path === '/';
    const isNotFoundPage = config.path === '*';

    const combinedVisits = pathsVisited.join('/');

    // index and not found pages end with invalid elements - so we must remove that invalid character
    const path =
      isNotFoundPage || isIndexPage
        ? `/${combinedVisits.substring(0, combinedVisits.length - 1)}`
        : `/${combinedVisits}`;

    if (!Element) {
      throw new Error(
        'expected ' + path + ' to have a valid element to render'
      );
    }

    const Item = (
      <Route
        path={path}
        // we do exact on index pages only because the not found instances immediately follow.
        // ee do not do exact on on regular pages because we want leaf elements in the route system to have sub routes if they want
        // e.g. CrudTable elements have sub routes that come after routes within the file system route
        exact={isIndexPage}
        render={(routeProps) => {
          return elementsVisited.reduceRight(
            (children, Parent) => {
              return <Parent {...routeProps}>{children}</Parent>;
            },
            <Suspense fallback={<BlockingLoader />}>
              <Element {...routeProps} />
            </Suspense>
          );
        }}
      />
    );

    // note we return an array here, even though it is a single item - because we are recursive that's super helpful for the non leaf cases
    return [Item];
  }

  // we are somewhere in the tree and since each branch at the same depth will all have distinct names (file system enforced) we can do a simple trick to get the 'right' order
  // we want:
  // 1) "Simple" routes without url parameters first (-4)
  // 2) Routes with parameters (-3)
  // 3) ndex page (-2)
  // 4) not found page is last (-1)

  const sorted = sortBy(config.children, (x) =>
    x.path === '/' ? -2 : x.path === '*' ? -1 : x.path.startsWith(':') ? -3 : -4
  );

  return (
    sorted
      .map((x) => {
        // now that we are sorted we can call everything recursively
        const visited = [...pathsVisited, x.path];
        const elements =
          Element === null ? elementsVisited : [...elementsVisited, Element];
        return renderFlatRoutes(x, visited, elements);
      })
      // we want to return only a flattened array, since this would otherwise return an array of arrays
      // we can use a regular flat here because each sub branch has a distinct prefix and order within the branch is maintained (the sortBy above)
      .flat()
  );
}
