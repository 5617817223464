import classnames from 'classnames';

interface SubmissionStatusLabelProps {
  text: string;
  color: string;
  labelStyle?: string;
  prependLabel?: string;
}

export default function SubmissionStatusLabel({
  text,
  color,
  labelStyle,
  prependLabel,
}: SubmissionStatusLabelProps) {
  return (
    <label
      className={classnames(
        'flex items-center justify-center py-1 text-sm text-center border-0 rounded-md shadow-sm appearance-none',
        color,
        labelStyle
      )}>
      {prependLabel && <div className="pr-2 font-bold">{prependLabel}</div>}
      {text}
    </label>
  );
}
