import classNames from 'classnames';
import React, { ComponentProps, ElementType, ReactHTML } from 'react';

export type StyledElementButtonSolidColors =
  | 'red'
  | 'indigo'
  | 'green'
  | 'darkGreen'
  | 'yellow'
  | 'primary';

type AnyElement = ElementType | keyof ReactHTML;
export interface StyledElementButtonSolidProps<T extends AnyElement> {
  tag: T;
  forwardRef: ComponentProps<T>['ref'];
  className?: string;
  color: StyledElementButtonSolidColors;
}

/** styles a tag of type T to look just like a solid button via className */
export default function StyledElementButtonSolid<T extends AnyElement>({
  tag,
  forwardRef,
  className,
  color,
  ...props
}: StyledElementButtonSolidProps<T>) {
  return React.createElement(tag, {
    ...props,
    ref: forwardRef,
    className: classNames(
      className,
      'appearance-none flex justify-center items-center text-center px-4 py-2 text-sm font-medium border-0 rounded-md shadow-sm',
      'focus:outline-none focus:ring-2 focus:ring-offset-2',
      'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500',
      {
        'text-red-900 bg-red-200 hover:bg-red-300 focus:ring-red-300':
          color === 'red',
        'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500':
          color === 'indigo',
        'text-green-900 bg-green-200 hover:bg-green-300 focus:ring-green-300':
          color === 'green',
        'text-green-100 bg-green-500 hover:bg-green-400 focus:ring-green-700':
          color === 'darkGreen',
        'text-yellow-700 bg-yellow-200 hover:bg-yellow-300 focus:ring-yellow-400':
          color === 'yellow',
        'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded':
          color === 'primary',
      }
    ),
  }) as JSX.Element;
}
