import { CellProps } from 'react-table';
import { Cells } from 'envoc-table';
import { TreasuryStatusLabel } from './SubmissionStatus';

export default function TreasuryStatusCell(props: CellProps<[]>) {
  return (
    <Cells.Cell
      value={
        <TreasuryStatusLabel
          status={props.value}
          labelStyle="px-4 py-2 w-24 max-w-md"
        />
      }
    />
  );
}
