import { createContext } from 'react';

export interface ModalContext {
  openInstanceName: string;
  addVisible: (name: string) => void;
  removeVisible: (name: string) => void;
}

export default createContext<ModalContext>({
  openInstanceName: '',
  addVisible: () => {},
  removeVisible: () => {},
});
