import { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react';
import classnames from 'classnames';

export interface ButtonLinkProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {}

/** link that looks like the standard blue links people expect */
const ButtonLinkRef = forwardRef(ButtonLink);

export default ButtonLinkRef;

function ButtonLink(
  { className, children, ...props }: ButtonLinkProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <button
      {...props}
      ref={ref}
      className={classnames(
        className,
        'text-blue-500 ring-indigo-200 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 '
      )}>
      {children}
    </button>
  );
}
