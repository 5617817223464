import { CellProps } from 'react-table';
import { Cells } from 'envoc-table';

export default function MonthYearCell(props: CellProps<[]>) {
  const date = new Date(props.value);

  if (!props.value) {
    return <Cells.Cell value="" />;
  }

  return (
    <Cells.Cell
      value={date.toLocaleDateString('default', {
        timeZone: 'UTC',
        month: 'short',
        year: 'numeric',
      })}
    />
  );
}
