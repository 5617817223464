import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'envoc-table/dist/css/envoc-table-styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from 'app';
import { config } from '@fortawesome/fontawesome-svg-core';
import { configureTableCells } from 'envoc-table';
import {
  AffidavitDownloadCell,
  CustomNaCell,
  DateOnlyCell,
  ExplanationCell,
  MonthYearCell,
  PriorFiscalYearAdjustmentStatusCell,
  SheriffActionCell,
  SheriffStatusCell,
  TreasuryStatusCell,
} from 'shared/components';
import { createStore } from 'shared/state';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

config.autoAddCss = false;
const store = createStore();

configureTableCells({
  CustomNaCell: CustomNaCell,
  DateOnlyCell: DateOnlyCell,
  SheriffStatusCell: SheriffStatusCell,
  SheriffActionCell: SheriffActionCell,
  TreasuryStatusCell: TreasuryStatusCell,
  MonthYearCell: MonthYearCell,
  AffidavitDownloadCell: AffidavitDownloadCell,
  ExplanationCell: ExplanationCell,
  PriorFiscalYearAdjustmentStatusCell: PriorFiscalYearAdjustmentStatusCell,
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
/*serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        //@ts-ignore
        if (event.target?.state === 'activated') {
          window.location.reload();
        }
      });
      //By default an updated service worker will not take over from one that is already running
      //This message is handled in service-worker.ts to tell the updated version to always take over
      //This is always necessary if there are API changes so the correct data is sent to the server.
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
