import type { InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import useStandardFormInput from 'envoc-form/es/useStandardFormInput';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  validate?: any; // todo, real types for validation
}

export default function InputWithValidation(props: InputProps) {
  const [input, meta] = useStandardFormInput(props);

  return (
    <>
      <input
        {...input}
        className={classNames(
          'block w-full rounded-md shadow-sm focus:outline-none',
          props.className,
          {
            'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500':
              meta.error,
            'placeholder-gray-400 border border-gray-300 focus:border-indigo-500':
              !meta.error,
          }
        )}
      />
      {meta.error && (
        <div className="mt-1 text-sm text-red-600 ">{meta.error}</div>
      )}
    </>
  );
}
