// this is auto generated, don't try and change it
const routes = [
  {importPath: './src/routes/403.tsx', path: '403', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/403')},
  {importPath: './src/routes/404.tsx', path: '404', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/404')},
  {importPath: './src/routes/Admin/_Layout.tsx', path: 'Admin/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/_Layout')},
  {importPath: './src/routes/Admin/404.tsx', path: 'Admin/404', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/404')},
  {importPath: './src/routes/Admin/index.tsx', path: 'Admin/', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/index')},
  {importPath: './src/routes/Admin/Logs.tsx', path: 'Admin/Logs', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Logs')},
  {importPath: './src/routes/Admin/Parishes.tsx', path: 'Admin/Parishes', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Parishes')},
  {importPath: './src/routes/Admin/Settings.tsx', path: 'Admin/Settings', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Settings')},
  {importPath: './src/routes/Admin/Users.tsx', path: 'Admin/Users', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Users')},
  {importPath: './src/routes/ChangePassword.tsx', path: 'ChangePassword', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ChangePassword')},
  {importPath: './src/routes/ConfirmAccount.tsx', path: 'ConfirmAccount', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ConfirmAccount')},
  {importPath: './src/routes/ForgotPassword.tsx', path: 'ForgotPassword', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ForgotPassword')},
  {importPath: './src/routes/ForgotPasswordSuccess.tsx', path: 'ForgotPasswordSuccess', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ForgotPasswordSuccess')},
  {importPath: './src/routes/index.tsx', path: '', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/index')},
  {importPath: './src/routes/Login.tsx', path: 'Login', importFunc: () => import(/* webpackMode: "eager" *//* webpackChunkName: "login" */'./routes/Login')},
  {importPath: './src/routes/Printable/_Layout.tsx', path: 'Printable/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Printable/_Layout')},
  {importPath: './src/routes/Printable/$entityId/Printable.tsx', path: 'Printable/:entityId/Printable', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Printable/$entityId/Printable')},
  {importPath: './src/routes/Printable/PrintableRecordCard.tsx', path: 'Printable/PrintableRecordCard', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Printable/PrintableRecordCard')},
  {importPath: './src/routes/Printable/PrintableRecords.tsx', path: 'Printable/PrintableRecords', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Printable/PrintableRecords')},
  {importPath: './src/routes/Reconciliations/_Layout.tsx', path: 'Reconciliations/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Reconciliations/_Layout')},
  {importPath: './src/routes/Reconciliations/$entityId/Approvals.tsx', path: 'Reconciliations/:entityId/Approvals', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Reconciliations/$entityId/Approvals')},
  {importPath: './src/routes/ResetPassword.tsx', path: 'ResetPassword', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ResetPassword')},
  {importPath: './src/routes/ResetPasswordConfirmation.tsx', path: 'ResetPasswordConfirmation', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ResetPasswordConfirmation')},
  {importPath: './src/routes/Sheriff/_Layout.tsx', path: 'Sheriff/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Sheriff/_Layout')},
  {importPath: './src/routes/Sheriff/Deputies.tsx', path: 'Sheriff/Deputies', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Sheriff/Deputies')},
  {importPath: './src/routes/Sheriff/index.tsx', path: 'Sheriff/', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Sheriff/index')},
  {importPath: './src/routes/Sheriff/PriorFiscalYearAdjustments.tsx', path: 'Sheriff/PriorFiscalYearAdjustments', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Sheriff/PriorFiscalYearAdjustments')},
  {importPath: './src/routes/Sheriff/Reconciliations.tsx', path: 'Sheriff/Reconciliations', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Sheriff/Reconciliations')},
  {importPath: './src/routes/Sheriff/Submissions.tsx', path: 'Sheriff/Submissions', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Sheriff/Submissions')},
  {importPath: './src/routes/Treasury/_Layout.tsx', path: 'Treasury/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Treasury/_Layout')},
  {importPath: './src/routes/Treasury/Deputies.tsx', path: 'Treasury/Deputies', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Treasury/Deputies')},
  {importPath: './src/routes/Treasury/index.tsx', path: 'Treasury/', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Treasury/index')},
  {importPath: './src/routes/Treasury/ParishPayments.tsx', path: 'Treasury/ParishPayments', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Treasury/ParishPayments')},
  {importPath: './src/routes/Treasury/PriorFiscalYearAdjustments.tsx', path: 'Treasury/PriorFiscalYearAdjustments', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Treasury/PriorFiscalYearAdjustments')},
  {importPath: './src/routes/Treasury/Submissions.tsx', path: 'Treasury/Submissions', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Treasury/Submissions')},
  {importPath: './src/routes/TwoFactorLogin.tsx', path: 'TwoFactorLogin', importFunc: () => import(/* webpackMode: "eager" *//* webpackChunkName: "login" */'./routes/TwoFactorLogin')},
];

export default routes;